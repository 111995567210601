<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'

const props = withDefaults(
  defineProps<{
    loading: boolean
    skribbleRequired: boolean
    swisscomRequired: boolean
    showPolicy: boolean
  }>(),
  {
    loading: false,
  }
)

const emit = defineEmits<{
  (event: 'action' | 'close'): void
}>()

const { t } = useI18n()

const data = reactive({
  skribbleAccepted: false,
  swisscomAccepted: false,
})

const allConsentGiven = computed((): boolean => {
  return (!props.skribbleRequired || data.skribbleAccepted) && (!props.swisscomRequired || data.swisscomAccepted)
})
</script>

<template>
  <base-dialog
    data-cy="termsOfUseDialog"
    max-width="640"
    :title="t('viewer.legal.overlay.title')"
    :disabled="!allConsentGiven"
    :loading="loading"
    :action-text="t('viewer.legal.overlay.accept')"
    :cancel-text="t('viewer.legal.overlay.decline')"
    @action="emit('action')"
    @close="emit('close')"
  >
    <v-row>
      <v-col cols="12" sm="7">
        <p>
          {{ t('viewer.legal.overlay.text') }}
        </p>
        <div class="my-6 space-y-4">
          <template v-if="props.skribbleRequired">
            <skr-checkbox v-model="data.skribbleAccepted" data-cy="skribble_checkbox">
              <i18n-t
                keypath="viewer.legal.overlay.skribble.accept_new.label_text"
                tag="div"
                for="viewer.legal.overlay.skribble.accept_new.label_link_text"
              >
                <a
                  :href="t('viewer.legal.overlay.skribble.accept_new.label_link_url')"
                  target="_blank"
                  rel="noopener"
                  @click.stop
                  >{{ t('viewer.legal.overlay.skribble.accept_new.label_link_text') }}</a
                >
              </i18n-t>
            </skr-checkbox>
          </template>
          <template v-if="props.swisscomRequired">
            <skr-checkbox v-model="data.swisscomAccepted" data-cy="swisscom_checkbox">
              <i18n-t
                keypath="viewer.legal.overlay.swisscom.accept_new.label_text"
                tag="div"
                for="viewer.legal.overlay.swisscom.accept_new.label_link_text"
              >
                <a
                  :href="t('viewer.legal.overlay.swisscom.accept_new.label_link_url')"
                  target="_blank"
                  rel="noopener"
                  @click.stop
                  >{{ t('viewer.legal.overlay.swisscom.accept_new.label_link_text') }}</a
                >
              </i18n-t>
            </skr-checkbox>
          </template>
        </div>
        <small v-if="props.showPolicy">
          <i18n-t keypath="dialog.accept_terms.privacy_policy.text">
            <template #link>
              <a :href="t('global.legal.privacy_policy_link')" target="_blank" rel="noopener">{{
                t('dialog.accept_terms.privacy_policy.link_text')
              }}</a>
            </template>
          </i18n-t>
        </small>
      </v-col>
      <v-col v-if="$vuetify.display.smAndUp" order="1" sm="5">
        <responsive-image source="gtc-dialog" :width="142" :height="225" />
      </v-col>
    </v-row>
  </base-dialog>
</template>
